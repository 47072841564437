var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "schedule-container" },
    [
      _c("PageLayout", {
        scopedSlots: _vm._u([
          {
            key: "page-name",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "nav-title font-menu-large" },
                  _vm._l(_vm.navList, function (item) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "nav-title-item",
                        class: { active: item.id === _vm.currentPage },
                        on: {
                          click: function ($event) {
                            return _vm.changePage(item.id)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(item.title) + " ")]
                    )
                  }),
                  0
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "page-content",
            fn: function () {
              return [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    { staticClass: "calendar" },
                    [_c("Calendar", { on: { getFormDate: _vm.getFormDate } })],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "course-content" },
                    [
                      _vm._l(_vm.scheduleList, function (item, index) {
                        return [
                          _c("ClassBox", {
                            key: index,
                            attrs: {
                              courseClassify: item.courseStatus,
                              classListInfo: item,
                            },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }